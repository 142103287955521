.pdf-div {
  padding: 50px;
  background-color: #dedede;
  margin-bottom: 50px;
  height: 500px;
  overflow: scroll;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}
